import React from "react";

import Nav from './nav';

export default function Header() {

  return (
    <div className="header">
      <div className="container">
          <Nav />
      </div>
    </div>
  );
}
